import React from 'react';
import './myskill.css';
const skills = [
    "html5","css3","Javascript","bootstrap","php","node","react","font-awesome","java","Android","figma","github","wordpress","cpanel"
]
export default function Myskill() {
    return (
        <div className="myskill" id="myskill">
            <h1>Skills</h1>
            <div className="skills">
                {
                    skills.map((s) => (
                        <div skill>
                            <img src={"svg/"+s+".svg"} alt="" />
                            <div className="title">{s}</div >
                        </div>
                        ))
                }
            </div>
        </div>
    )
}