import React from 'react';
import './contact.css';

export default function Contact() {
    const contact = [
        {
            id:1,
            img:"svg/phone.svg",
            add:"+251 946 23 29 13",
            href:"tel:0946232913"
        },
        {
            id:2,
            img:"svg/phone.svg",
            add:"+251 704 21 29 13",
            href:"tel:09704212913"
        },
        {
            id:3,
            img:"svg/telegram.svg",
            add:"Telegram",
            href:"https://t.me/aycodes"
        },
        {
            id:4,
            img:"svg/at.svg",
            add:"ahmefyusuf541@gmail.com",
            href:"mailto:ahmefyusuf541@gmail.com"
        },
        {
            id:5,
            img:"svg/instagram.svg",
            add:"Instagram",
            href:"https://instagram.com/_aycodes"
        },
        {
            id:6,
            img:"svg/youtube.svg",
            add:"Youtube",
            href:"https://youtube.com/@aycodes"
        },
        {
            id:7,
            img:"svg/tiktok.svg",
            add:"Tiktok",
            href:"https://tiktok.com/@aycodes"
        },
        {
            id:8,
            img:"svg/github.svg",
            add:"Github",
            href:"https://github.com/ahmefyusuf541"
        },
        {
            id:8,
            img:"svg/linkedin.svg",
            add:"Linkedin",
            href:"https://linkedin.com/in/aycodes"
        },
        {
            id:9,
            img:"svg/whatsapp.svg",
            add:"Whats app",
            href:"https://wa.me/0946232913"
        },
        {
            id:10,
            img:"svg/twitter.svg",
            add:"Twitter",
            href:"https://x.com/aycodes"
        }
    ]
    return (
        <div className="contact" id="contact">
            <h1>Contact me</h1>
            <div className="container">
                <div className="left">
                    <ul>
                        {contact.map((d) => (
                            <li>
                            <img src={d.img} alt="" />
                            <a href={d.href} Target="_blank">{d.add}</a>
                        </li>
                        ))
                        }
                    </ul>
                </div>
                <div className="right">
                        <img src="svg/handshake.svg" alt="" />
                </div>
            </div>
        </div>
    )
}