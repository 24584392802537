import React from 'react';
import './works.css';
import { useState } from 'react';
export default function Works() {
    const [currentSlide,setCurrentSlide] = useState(0);
    const data = [
        {
            id:1,
            icon:"img/acc.png",
            title:"Audit Cost Calculator",
            desc:"I developed it during my internship program for Office of the Federal Auditor General. It uses for planning and reporting the cost of audits.  ",
            img:"img/acc.png"
        },
        {
            id:2,
            icon:"img/zmd.png",
            title:"ZMDNA",
            desc:"I have developed this from scratch and it was registered by Ethiopian Intellectual Property Authority as it  is my creactivity.It uses to list peoples in the same family, shows family tree view and use as social media to share events with others.",
            img:"img/zmd.png",
            link:"https://zmdna.net"
        },
        {
            id:3,
            icon:"img/fms.png",
            title:"Factory Managment System",
            desc:"I developed it as a final project for and get certified by the computer scinece department as it was the best of all final projects.It inludes managing employees, raw materials, products, sales and recieving orders from customers.",
            img:"img/fms.png"
        },
    ];
    const handleClick = (way) =>{
        way === "left" 
        ? setCurrentSlide(currentSlide > 0 ? currentSlide-1:data.length-1)
        : setCurrentSlide(currentSlide<data.length-1 ? currentSlide+1: 0);
    }    
    // setInterval(handleClick("left"),1000);
    setInterval( () => handleClick,1000)

    return (
        <div className="works" id="works">
            <div className="slider" style={{left:`-${currentSlide * 100}vw`}}>
                    {data.map((d) => (
                    <div className="container">
                        <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <div className="imgContainer">
                                    <img src={d.icon} alt="" />
                                </div>
                                <h2>{d.title}</h2>
                                <p>
                                    {d.desc}
                                </p>
                                {d.link && <a href={d.link} Target="_blank">Visit ➡</a>}
                            </div>
                        </div>
                        <div className="right">
                            <img src={d.img} alt="" />
                        </div>
                    </div>
                </div>
                        ))
                    }
            </div>
            <img src="img/arrow.svg" alt="" className="arrow left" onClick={()=> handleClick("left")}/>
            <img src="img/arrow.svg" alt="" className="arrow right" onClick={()=> handleClick()}/>
        </div>
    )
}