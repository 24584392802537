import Topbar from "./componenets/topbar/Topbar";
import Home from "./componenets/home/Home";
import Myskill from "./componenets/myskill/Myskill";
import Works from "./componenets/works/Works";
import Contact from "./componenets/contact/Contact";
import Menu from "./componenets/menu/Menu";
import Achivements from "./componenets/achivements/Achivements";
import './app.css';
import { useState } from "react";

function App() {
  const [menuOpen,setMenuOpen] = useState(false);
  return (
    <div className="app">
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <Menu  menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <div className="sections">
        <Home />
        <Myskill />
        <Achivements />
        <Works />
        <Contact />
      </div>
    </div>
  );
}

export default App;
