import React from 'react';
import './topbar.css';

export default function Topbar({menuOpen,setMenuOpen}) {
    return (
        <div className={"topbar "+(menuOpen && "active" )}>
            <div className="wrapper">
                <div className="left">
                    <a href="#home" className="logo">
                        <img src="img/photo.jpg" alt="" />
                        Ahmed</a>

                </div>
                <div className="right">
                    <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                        <span className="line1"></span>
                        <span className="line2"></span>
                        <span className="line3"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}