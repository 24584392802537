import './achivements.css';
export default function Achivements() {
  
    const achivements = [
        {
            id:1,
            img:"svg/user-graduate.svg",
            desc:"Graduated from Arsi University in computer science with CGPA of 3.81 and passed ExitExam."
        },
        {
            id:2,
            img:"svg/handshake.svg",
            desc:"Recieved recommendation leter from Arsi university and office of the Federal Auditor General."
        },
        {
            id:3,
            img:"svg/copyright.svg",
            desc:"Ethiopian Intellectual Property Authority has registered my ZMDNA project to protect copyright."
        },
        {
            id:4,
            img:"svg/code.svg",
            desc:"Taken many programming courses from well known course providers like solo leardn and programming hub and get certified. "
        },
        {
            id:5,
            img:"svg/laptop-code.svg",
            desc:"My final project was selected as the best final project of the year and get recognized by university. "
        },
        {
            id:6,
            img:"svg/hand-holding-usd.svg",
            desc:"Taken two Entrepreunial trainings and get certified. "
        }
    ];

    return (
        <div className="achievements" id="achievements">
            <h1>Achivements</h1>
            <div className="container">
                {
                    achivements.map((a) => (
                    <div className="item">
                        <img src={a.img} alt="" />
                        <span>{a.desc}</span>
                    </div>
                    ))
                }
            </div>
        </div>
    )
}