import React from 'react';
import './menu.css';

export default function Menu({menuOpen,setMenuOpen}) {
    return (
        <div className={"menu "+(menuOpen && "active") }id="menu">
            <ul>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#home">Home</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#achievements">Achievements</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#myskill">My skills</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#works">Projects</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="#contact">Contact</a>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                    <a href="svg/cv.pdf" download>Download cv</a>
                </li>
            </ul>
        </div>
    )
}