import './home.css';

export default function Home() {   
    return (
        <div className="home" id="home">
            <div className="left">
                <div className="imgContainer">
                    <img src="img/photo.jpg" alt="" />
                </div>
            </div>
            <div className="right">
                <div className="wrapper">
                    <h2>Hi, there!</h2>
                    <h1>I'm Ahmed Yusuf</h1>
                    <h3>Ful-stack developer.</h3>
                </div>
            </div>
        </div>
    )
}